/*
Documentation

this file handles password recovery by sending a hashed security code to a users email
it then verifies the code and lets them update their password

*/

import { useState, useCallback, useEffect } from "react";
import { Card, CardHeader, CardBody, CardTitle, CardFooter, Form, Input, Modal } from "reactstrap";

import Circle from 'components/markup/loading/Circle'

import _auth from '_functions/auth';

import ModalToggler from 'components/functional/modals/Toggler'


const ModalForgot = ({showModal, toggleModal}) => (
    <Modal
            className="modal-dialog-centered"
            isOpen={showModal}
            toggle={toggleModal}
            size="sm"
        >

            <div className="modal-header">
                <h5 className="modal-title">Forgot Username</h5>
                <button
                    aria-label="Close"
                    className="close"
                    data-dismiss="modal"
                    type="button"
                    onClick={toggleModal}
                >
                    <span aria-hidden={true}>×</span>
                </button>
            </div>

            <div className="modal-body">
                <p className="text-sm mb-0">If you have forgotten your username you will need to contact your system administrator before logging in.</p>
            </div>

            <div className="modal-footer">
                <button className="btn btn-outline-warning" onClick={toggleModal}>
                    Close
                </button>
            </div>

        </Modal>
)

const ForgotConfirmUsername = ({onConfirmedUsername}) => {

    const [loading, setLoading] = useState(false)
    const [username, setUsername] = useState('')
    const [err, setErr] = useState('')

    //on enter simulate the form being submitted for better UI
	const _handleKeyDown = useCallback((e) => {
		if (e.key === 'Enter') document.getElementById("showUsernameButton").click();
	}, [])

    const onSendCode = useCallback(async (e) => {

        if(!username) return setErr('You must add your email before Proceeding')

        //send a security code to the server
        //do not console.log this response in production as it holds
        //the code that was created and hashed
        setLoading(true)
        const createCode = await _auth.security_code.create({username})
        setLoading(false)

        if(createCode.success) {
            onConfirmedUsername(username)
        } else {
            setErr(createCode.message)
        }

    }, [onConfirmedUsername, username])

    const focusInput = useCallback(() => {
        const tryFocus = (tries = 0) => {
            if(tries > 20) return;
            const input = document.getElementById('forgot-email-input')
            if(input) {
                input.focus();
            } else {
                setTimeout(() => {
                    tryFocus(tries + 1) 
                }, 200)
            }
        }
        tryFocus();
    }, [])

    useEffect(() => {
        focusInput();
		window.addEventListener('focus', focusInput)
		return () => window.removeEventListener('focus', focusInput)
    }, [focusInput])

    return (
        <Form role="form" onSubmit={(e) => e.preventDefault()}>

            <Card className="bg-white border-0 mb-0 z-depth-2">

                <CardHeader>
                    <CardTitle className="mb-0">Forgot Password - Step 1 of 3</CardTitle>
                </CardHeader>

                <CardBody>

                    {loading ? (
                        <div className="py-6">
                            <Circle />
                        </div>
                    ) : (
                        <div>
                            <label className="form-control-label">Enter Your Username</label>
                            <Input
                                id="forgot-email-input"
                                onKeyDown={_handleKeyDown}
                                placeholder="Username"
                                type="email"
                                name="email"
                                value={username}
                                onChange={(e) => setUsername(e.target.value)}
                            />
                            
                            <p className="text-sm mb-0 text-warning text-right">
                                <small className=" bold cursor-pointer">
                                    <ModalToggler component={ModalForgot}>
                                        Forgot Username?
                                    </ModalToggler>
                                </small>
                            </p>
                        </div>
                    )}

                </CardBody>

                {err && (
                    <CardFooter>
                        <p className="text-sm text-danger font-weight-bold my-2 ">{err}</p>
                    </CardFooter>
                )}

                <CardFooter>
                    <button id="showUsernameButton" onClick={onSendCode} className="btn btn-outline-success btn-block">
                        Send Recovery Code
                    </button>
                </CardFooter>

            </Card>

        </Form>
    )
	
}

export default ForgotConfirmUsername

