


import { useCallback, useEffect, useState } from 'react';
import { connect } from 'react-redux'
import { CardFooter, CardHeader, CardTitle, Card, CardBody, Col, FormGroup, Input, Row } from "reactstrap";

import _auth from '_functions/auth';

import Circle from 'components/markup/loading/Circle'

const ForgotConfirmCode = ({username, onConfirmedCode}) => {

    const [message, setMessage] = useState(false);
    const [err, setErr] = useState(false);
    const [code, setCode] = useState({});

    const [loading, setLoading] = useState('');

    const focusInput = useCallback((num) => {
        let timesTried = 0;

        const focus = () => {
            if(timesTried > 20) return;
            const input = document.getElementById(`code-input-${num}`);
            if(input) return input.focus();

            setTimeout(() => {
                timesTried++;
                focus();
            }, 100)
        }
        focus()
    }, [])    

    const onVerify = useCallback(async (_code) => {

        // if code is passed in use that code, else use state code if button is pressed
        const CODE = _code ? _code : code;

        if(!CODE[1] || !CODE[2] || !CODE[3] || !CODE[4] || !CODE[5] || !CODE[6]) return;

        let fullCode = '';
        Object.keys(CODE).forEach(key => fullCode += CODE[key]);

        setLoading(true)
        const confirmedCode = await _auth.security_code.validate({username, code: fullCode})
        setLoading(false)
        focusInput(6)

        if(confirmedCode.success) {
            onConfirmedCode(fullCode, confirmedCode.data.questions)
        } else {
            setErr(confirmedCode.message)
        }

    }, [code, username, onConfirmedCode, focusInput])

    const onSetCode = useCallback((num, value) => {
        const _code = JSON.parse(JSON.stringify(code));
        _code[num] = value ? value.slice(-1) : value;
        setCode(_code)

        const nextInput = document.getElementById(`code-input-${num + 1}`);
        if(nextInput) return nextInput.focus();

        onVerify(_code);
    }, [code, onVerify])

    const onKeyDown = useCallback((num, e) => {
        if (e.key === 'Delete' || e.key === 'Backspace' || e.code === 8) {

            e.preventDefault();

            const _code = JSON.parse(JSON.stringify(code));
            _code[num] = '';
            setCode(_code)

            const lastInput = document.getElementById(`code-input-${num - 1}`);
            if(lastInput) lastInput.focus();
        }
    }, [code])

    const onResend = useCallback(async () => {
        _auth.security_code.create({username})
        setMessage('A new code was just sent to your email, if you do not see it try checking your junk/spam folder.')
        setTimeout(() => {
            setErr(false)
        }, 10000)
    }, [username])

    useEffect(() => {
        focusInput(1)
    }, [focusInput])

    return (

        <Card className="bg-white border-0 mb-0 z-depth-2" >

            <CardHeader>
                <CardTitle className="mb-0">Enter Code</CardTitle>
            </CardHeader>

            <CardBody className="bg-secondary">
                <p className="text-sm mb-0">Please Enter The Security Code Sent To Your Email</p>
            </CardBody>

            <CardBody className="mfa-auth-code">
                {loading ? (
                    <div className="py-6"><Circle /></div>
                ) : (
                    <FormGroup className="mb-0">

                        <Row>
                            <Col xs={2}>
                                <Input 
                                    id="code-input-1"
                                    onKeyDown={(e) => onKeyDown(1, e)}
                                    type="text"
                                    value={code[1] || ''}
                                    onChange={(e) => onSetCode(1, e.target.value)}
                                />
                            </Col>
                            <Col xs={2}>
                                <Input 
                                    id="code-input-2"
                                    onKeyDown={(e) => onKeyDown(2, e)}
                                    type="text"
                                    value={code[2] || ''}
                                    onChange={(e) => onSetCode(2, e.target.value)}
                                />
                            </Col>
                            <Col xs={2}>
                                <Input 
                                    id="code-input-3"
                                    onKeyDown={(e) => onKeyDown(3, e)}
                                    type="text"
                                    value={code[3] || ''}
                                    onChange={(e) => onSetCode(3, e.target.value)}
                                />
                            </Col>
                            <Col xs={2}>
                                <Input 
                                    id="code-input-4"
                                    onKeyDown={(e) => onKeyDown(4, e)}
                                    type="text"
                                    value={code[4] || ''}
                                    onChange={(e) => onSetCode(4, e.target.value)}
                                />
                            </Col>
                            <Col xs={2}>
                                <Input 
                                    id="code-input-5"
                                    onKeyDown={(e) => onKeyDown(5, e)}
                                    type="text"
                                    value={code[5] || ''}
                                    onChange={(e) => onSetCode(5, e.target.value)}
                                />
                            </Col>
                            <Col xs={2}>
                                <Input 
                                    id="code-input-6"
                                    onKeyDown={(e) => onKeyDown(6, e)}
                                    type="text"
                                    value={code[6] || ''}
                                    onChange={(e) => onSetCode(6, e.target.value)}
                                />
                            </Col>
                        </Row>
                        
                    </FormGroup>
                )}
            </CardBody>

            <CardBody className="bg-secondary">
                <p className="text-sm mb-0">Didn't receive a code? <b className="text-warning cursor-pointer" onClick={onResend}>Resend It</b></p>
            </CardBody>

            {err ? (
                <CardFooter>
                    <p className="text-sm text-danger mb-0">{err}</p>
                </CardFooter>
            ) : null}
          
            {message ? (
                <CardFooter>
                    <p className="text-sm text-info mb-0">{message}</p>
                </CardFooter>
            ) : null}

            <CardFooter>
                <button onClick={() => onVerify(code)} className="btn btn-outline-success btn-block">Confirm Code</button>
            </CardFooter>

        </Card>

    )
    
}


const mapStateToProps = state => {
    return {
		load: state.load,
    };
};

export default connect(mapStateToProps, '')(ForgotConfirmCode);