/*
Documentation

this file handles password recovery by sending a hashed security code to a users username
it then verifies the code and lets them update their password

*/

import { useState, useCallback, useEffect } from "react";
import { Card, CardHeader, CardBody, CardTitle, CardFooter, Form, FormGroup, Input } from "reactstrap";

import Circle from 'components/markup/loading/Circle'

import _auth from '_functions/auth';

const ForgotConfirmQuestions = ({username, questions, onConfirmedQuestions}) => {

    const [loading, setLoading] = useState(false)
    const [answer1, setAnswer1] = useState('')
    const [answer2, setAnswer2] = useState('')
    const [answer3, setAnswer3] = useState('')
    const [err, setErr] = useState('')

    //on enter simulate the form being submitted for better UI
	const _handleKeyDown = useCallback((e) => {
		if (e.key === 'Enter') document.getElementById("confirm-questions-button").click();
	}, [])

    const onConfirmQuestions = useCallback(async (e) => {
        if(!answer1 || !answer2 || !answer3) return setErr('You must answer all questions before proceeding')

        const _answers = {
            q1: answer1,
            q2: answer2,
            q3: answer3,
        }

        // validate security questions match those given by user previously
        setLoading(true)
        const createCode = await _auth.security_questions.validate({
            username, 
            answers: _answers
        })

        setLoading(false)

        if(createCode.success) {
            onConfirmedQuestions(_answers)
        } else {
            setErr(createCode.message)
        }

    }, [username, answer1, answer2, answer3, onConfirmedQuestions])

    const focusInput = useCallback(() => {
        const tryFocus = (tries = 0) => {
            if(tries > 20) return;
            const input = document.getElementById('confirm-questions-input-1')
            if(input) {
                input.focus();
            } else {
                setTimeout(() => {
                    tryFocus(tries + 1) 
                }, 200)
            }
        }
        tryFocus();
    }, [])

    useEffect(() => {
        focusInput()
    }, [focusInput])

    return (
        <Form role="form" onSubmit={(e) => e.preventDefault()}>

            <Card className="bg-white border-0 mb-0 z-depth-2">

                <CardHeader>
                    <CardTitle className="mb-0">Security Questions</CardTitle>
                </CardHeader>

                <CardHeader>
                    <p className="mb-0">Your account requires the following three questions be answered correctly before changing your password.</p>
                </CardHeader>

                <CardBody>

                    {loading ? (
                        <div className="py-6"><Circle /></div>
                    ) : (
                        <div>
                            <FormGroup>
                                <label className="form-control-label">{questions.q1}</label>
                                <Input
                                    id="confirm-questions-input-1"
                                    onKeyDown={_handleKeyDown}
                                    placeholder=""
                                    type="text"
                                    value={answer1}
                                    onChange={(e) => setAnswer1(e.target.value)}
                                />
                            </FormGroup>

                            <FormGroup>
                                <label className="form-control-label">{questions.q2}</label>
                                <Input
                                    id="confirm-questions-input-2"
                                    onKeyDown={_handleKeyDown}
                                    placeholder=""
                                    type="text"
                                    value={answer2}
                                    onChange={(e) => setAnswer2(e.target.value)}
                                />
                            </FormGroup>

                            <FormGroup>
                                <label className="form-control-label">{questions.q3}</label>
                                <Input
                                    id="confirm-questions-input-3"
                                    onKeyDown={_handleKeyDown}
                                    placeholder=""
                                    type="text"
                                    value={answer3}
                                    onChange={(e) => setAnswer3(e.target.value)}
                                />
                            </FormGroup>
                        </div>
                    )}

                </CardBody>

                {err && (
                    <CardFooter>
                        <p className="text-sm text-danger font-weight-bold my-2 ">{err}</p>
                    </CardFooter>
                )}

                <CardFooter className="text-right">
                    <button id="confirm-questions-button" onClick={onConfirmQuestions} className="btn btn-outline-success">
                        Confirm Answers
                    </button>
                </CardFooter>

            </Card>

        </Form>
    )
	
}

export default ForgotConfirmQuestions

