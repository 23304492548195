/*
Documentation

this file handles password recovery by sending a hashed security code to a users email
it then verifies the code and lets them update their password

*/

import React, { useCallback, useState } from 'react';
import { connect } from 'react-redux';
import { Link, Redirect } from "react-router-dom";
import { Col, Container, Row } from "reactstrap";
import { Helmet } from 'react-helmet'

import ConfirmUsername from './ConfirmUsername'
import ConfirmCode from './ConfirmCode'
import UpdatePassword from './UpdatePassword'
import ConfirmQuestions from './ConfirmQuestions'

const Forgot = ({match, load}) => {

    const [view, setView] = useState('ConfirmUsername');
    const [username, setUsername] = useState('');
    const [code, setCode] = useState('');
    const [redirect, setRedirect] = useState('');
    const [questions, setQuestions] = useState({});
    const [answers, setAnswers] = useState({});

    const onConfirmedUsername = useCallback((_username) => {
        setUsername(_username)
        setView('ConfirmCode')
    }, [])

    const onConfirmedCode = useCallback((_code, _questions) => {
        setCode(_code)
        if(_questions) {
            setQuestions(_questions);
            return setView('UpdatePassword')
        }
        setView('UpdatePassword')
    }, [])

    const onConfirmedQuestions = useCallback(_answers => {
        setAnswers(_answers);
        setView('UpdatePassword')
    }, [])

    const onPasswordUpdate = useCallback(() => {
        setRedirect(`/?reset=true`)
    }, [])

    if(redirect) return <Redirect to={redirect} />

    return (
        <div>
            <Helmet>
                <title>Forgot Password</title>
                <meta name="description" content="Forgot Password" />
            </Helmet>

            <div className="header  py-7 py-lg-8 pt-lg-9">
                <Container>
                    <div className="header-body text-center mb-7">
                        <Row className="justify-content-center">
                            <Col className="px-5" lg="6" md="8" xl="5">

                                <img style={{width: 250}} alt="..."  src={load.PLATFORM.LOGO} />
                                <p className="text-lead mt-3">
                                    <b className="font-weight-bold d-block">Forgot your password?</b>
                                    We all do it too, just fill out the form below.
                                </p>
                            </Col>
                        </Row>
                    </div>
                </Container>
            </div>

			<Container className="mt--9 pb-5">
				<Row className="justify-content-center">
					<Col lg="5" md="7">

                        {view === 'ConfirmUsername' && (
                            <ConfirmUsername
                                onConfirmedUsername={onConfirmedUsername}
                            />
                        )}

                        {view === 'ConfirmCode' && (
                            <ConfirmCode
                                username={username}
                                onConfirmedCode={onConfirmedCode}
                            />
                        )}

                        {view === 'ConfirmQuestions' && (
                            <ConfirmQuestions
                                username={username}
                                questions={questions}
                                onConfirmedQuestions={onConfirmedQuestions}
                            />
                        )}


                        {view === 'UpdatePassword' && (
                            <UpdatePassword
                                code={code}
                                username={username}
                                onPasswordUpdate={onPasswordUpdate}
                                answers={answers}
                            />
                        )}

						<Row className="mt-3">

                            <Col xs="12" className="text-center">
                                <Link to="/" >
                                    <p className="text-sm font-weight-bold"><i className="fas fa-arrow-left mr-2" /> Login</p>
								</Link>
							</Col>

						</Row>

					</Col>
				</Row>
			</Container>
        </div>
    )
}

const mapStateToProps = state => {
    return {
		load: state.load,
    };
};

export default connect(mapStateToProps, '')(Forgot);

