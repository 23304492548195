import { useCallback, useState, useEffect } from "react";
import { Card, CardHeader, CardBody, CardTitle, CardFooter, Form, FormGroup, Input } from "reactstrap";
import _auth from '_functions/auth';

import { toast } from 'react-toastify'
import Circle from 'components/markup/loading/Circle'

const ForgotUpdatePassword = ({username, code, onPasswordUpdate}) => {

    const [loading, setLoading] = useState('')
    const [err, setErr] = useState('')
    const [password, setPassword] = useState('')
    const [passwordConfirm, setPasswordConfirm] = useState('')

    //on enter simulate the form being submitted for better UI
	const _handleKeyDown = useCallback((e) => {
		if (e.key === 'Enter') document.getElementById("showPasswordUpdateButton").click();
	}, [])
    

    const onPasswordSet = useCallback(async (e) => {
        //update the users password
        setLoading(true)
        const updatePassword =  await _auth.password.reset({
            // answers,
            password,
            passwordConfirm,
            username,
            code,
        })
        setLoading(false)

        if(updatePassword.success) {
            onPasswordUpdate()
            toast.success('Your password has been reset!')
        } else {
            setErr(updatePassword.message)
        }

    }, [code, username, onPasswordUpdate, password, passwordConfirm])

    useEffect(() => {
        const tryFocus = (tries = 0) => {
            if(tries > 20) return;
            const input = document.getElementById('add-new-password-input')
            if(input) {
                input.focus();
            } else {
                setTimeout(() => {
                    tryFocus(tries + 1) 
                }, 200)
            }
        }
        tryFocus();
    }, [])


	return (
        <Form onSubmit={e => e.preventDefault()}>

            <Card className="bg-white border-0 mb-0 z-depth-2">

                <CardHeader>
                    <CardTitle className="mb-0">Forgot Password - Step 3 of 3</CardTitle>
                </CardHeader>

                <CardBody>
                    {loading ? (
                        <div className="py-6">
                            <Circle />
                        </div>
                    ) : (
                        <div>
                            <FormGroup>
                                <label className="form-control-label">Enter your new password.</label>
                                <Input
                                    id="add-new-password-input"
                                    onKeyDown={_handleKeyDown}
                                    placeholder=""
                                    type="password"
                                    value={password}
                                    onChange={(e) => setPassword(e.target.value)}
                                />
                            </FormGroup>

                            <FormGroup>
                                <label className="form-control-label">Confirm Password.</label>
                                <Input
                                    onKeyDown={_handleKeyDown}
                                    placeholder=""
                                    type="password"
                                    value={passwordConfirm}
                                    onChange={(e) => setPasswordConfirm(e.target.value)}
                                />
                            </FormGroup>
                        </div>
                    )}
                </CardBody>

                {err && (
                    <CardFooter>
                        <p className="text-sm text-danger font-weight-bold my-2 ">{err}</p>
                    </CardFooter>
                )}

                <CardFooter className="text-right">
                    <button id="showPasswordUpdateButton" onClick={onPasswordSet} className="my-4 btn btn-outline-success">
                        Update Password
                    </button>
                </CardFooter>

            </Card>

        </Form>
    )
}

export default ForgotUpdatePassword

