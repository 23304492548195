import React, { useEffect, useCallback, useState } from "react";
import _auth from '_functions/auth';

import Circle from 'components/markup/loading/Circle'

const Logout = () => {

    const [err, setErr] = useState(null);

    const forceLogout = useCallback(async () => {
        const logout = await _auth.logout()

        if(logout.success) {
            window.location.href = '/' + window.location.search
        } else {
            setErr(logout.message ? logout.message : `Something's not right, please refresh your page and try again.`);
        }
    }, [])

    useEffect(() => {
        forceLogout()
    }, [forceLogout])

    return (
        <div className="text-center">

            <div className="pt-6">
                <Circle />
                <p>Securely Logging You Out</p>
            </div>

           {err && (
               <div className="align-self-center w-100">
                   <h2><i className="fas fa-exclamation-triangle text-danger mr-2 " /> {err}</h2>
               </div>
           )}

        </div>
    );
}


export default Logout

