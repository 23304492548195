/*
Documentation

this page holds all of our layouts and application functions
that need to fire before the app runs

*/

import React, { Component } from 'react';

import { BrowserRouter, Route, Switch } from "react-router-dom";
import { connect } from 'react-redux';

import Auth from 'views/auth/layout';
import Errors from 'views/errors/layout';
import FailedConnection from 'views/errors/pages/errors/FailedConnection';

import SystemOnlineStatus   from 'components/markup/system/OnlineStatus'
import SystemLoader         from 'components/markup/system/Loader'
import StandardLoader       from 'components/markup/system/StandardLoader'

import { ToastContainer } from 'react-toastify';

import init from './init'

class App extends Component {

    state = {
        loaded: false,
        error: false
    };

    componentDidMount = async () => {
       const loadedSuccessfully = await init();
       this.setState({loaded: true, error: !loadedSuccessfully});
    }

    componentDidCatch = (e) => {
        //don't redirect if we are developing
        if(window.location.hostname !== 'localhost') {
            this.setState({shouldRedirect: `/errors/standard?error=${e}&referrerurl=${window.location.href}`})
        }
    }

    render() {

        const { online } = this.props;

        if(this.state.shouldRedirect) return window.location.href = this.state.shouldRedirect;
        if(!this.state.loaded)        return <div></div>;
        if(this.state.error)          return <FailedConnection />;

        return (

            <div className={online ? 'online' : 'offline'}>

                <SystemOnlineStatus />
                <SystemLoader />
                <StandardLoader />
                <ToastContainer 
                    autoClose={3000} 
                />
                
                <BrowserRouter>
                    <Switch>
                        <Route path="/errors"  render={props => <Errors  {...props} /> } />
                        <Route path="/"   render={props => <Auth    {...props} />  } />
                    </Switch>
                </BrowserRouter>

            </div>

        )

    }

}

const mapStateToProps = state => {
	return {
	    online: state.system.online,
	};
};

export default connect(mapStateToProps, '')(App);