/*
Documentation

this is the wrapping component for all auth routes
if a user is already known and logged in it will redirect
them off to their destination

if not destination is know we will redirect to /admin

*/

import React, { Component } from "react";
import {  Redirect } from "react-router-dom";
import { Route, Switch } from "react-router-dom";
import routes from "../layout/routes";


class Auth extends Component {

    state = {
        redirect: ''
    }

    getRoutes = routes => {
        return routes.map((prop, key) => {

            return (
                <Route
                    path={`${prop.path}`}
                    component={prop.component}
                    key={key}
                />
            );

        });
    };

    componentDidCatch = (e) => {
        console.log(e);

        //don't redirect if we are developing
        if(window.location.hostname !== 'localhost') {
            this.setState({redirect: `/errors/standard?error=${e}&referrerurl=${window.location.href}`})
        }
    }

    componentDidUpdate(e) {
        if (e.history.pathname !== e.location.pathname) {
            document.documentElement.scrollTop = 0;
            document.scrollingElement.scrollTop = 0;

            if(this.refs.mainContent) {
                this.refs.mainContent.scrollTop = 0;
            }
        }
    }

    render() {

        if(this.state.redirect) return <Redirect to={this.state.redirect} />

        return (
            <div className="layout-auth" ref="mainContent">
                <Switch>
                    {this.getRoutes(routes)}
                    <Redirect from="*" to="/" />
                </Switch>
            </div>
        );
        
    }
}

export default Auth