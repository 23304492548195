/*
Documentation

note that this file is not really used, users must be created by admins
random people cannot just "sign up"

*/

import owasp from 'owasp-password-strength-test';
import React from "react";
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import { Button, Card, CardBody, CardHeader, Col, Container,  Row } from "reactstrap";
import keys from 'keys';
import {  architeckCall } from 'database';
import { Helmet } from 'react-helmet'

import { getUrlParameter } from 'utils/urls';

const required_form_fields = [
    'given_name',
    'family_name',
    'email',
    'password',
    'password_confirm',
    'terms'
]

class Register extends React.Component {

    state = { };

    //on enter simulate the form being submitted for better UI
	_handleKeyDown = (e) => {
		if (e.key === 'Enter') {

			document.getElementById("createAccountButton").click();
		}
	}

	getUrlParameter = (name) => {
		name = name.replace(/[[]/, '\\[').replace(/[\]]/, '\\]');
		let regex = new RegExp('[\\?&]' + name + '=([^&#]*)');
		let results = regex.exec(window.location.search);
		return results === null ? '' : decodeURIComponent(results[1].replace(/\+/g, ' '));
	};

    onSignUp = async () => {

        this.setState({canSave: false, termsState: 'valid', errorMessage: null})
        //copy state
        let newState = Object.assign({}, this.state);
        //assume we have no errors
        let errors = 0;

        const pt = this.state.passwordState


        required_form_fields.forEach((field) => {

            if(field === 'terms') {

                if(!this.state.terms) {
                    errors++
                    this.setState({termsState: 'invalid'})
                }

            }

            //check each required field
            if(newState[field]) {
                newState[field + "State"] = 'valid';
            } else {
                newState[field + "State"] = 'invalid';
                errors++
            }

        })

        this.setState({
            ...newState
        });

          //et the user try again but stop form saving
        if(errors) {
            this.setState({canSave: true})
        } else {


            if(pt & pt.optionalTestErrors && pt.optionalTestErrors.length) {
                return
            }

            const created = await architeckCall({
                method: 'post',
                url: '/api/v1/auth/create_account',
                data: newState
            })

            if(created.success) {

                const redirect = this.getUrlParameter('redirect')

                if(redirect) {
                    window.location.href = redirect;
                    return;
                } else {
                    window.location.href = '/admin';
                    return;
                }

            } else {

                this.setState({errorMessage: created.message})

            }

             //after load make sure button work again
             this.setState({canSave: true})

        }

    }

    onInputChange = (e, stateName) => {

        let pt = this.state.passwordTest

        if(stateName === 'password') {
            pt = owasp.test(e.target.value)
        }

        let newState = Object.assign({}, {
            ...this.state,
            passwordTest: pt
        });
        newState[stateName] = e.target.value;



          if (e.target.value === "") {
            newState[stateName + "State"] = "invalid";
          } else {
            newState[stateName + "State"] = "valid";
          }


        this.setState({
            ...newState
        });
    };

    componentDidMount = async () => {

        const viewing_user = this.props.viewing_user;

        if(viewing_user && viewing_user.logged_in) {

            const redirect = getUrlParameter('redirect');

            if(redirect && (redirect.includes(keys.APP_URL) || redirect.includes(keys.ADMIN_URL))) {
                window.location = redirect;
            } else {
                window.location = keys.APP_URL;
            }

        } else {
			this.setState({loaded: true})
		}

    }

	render() {

        if(!this.state.loaded) return <div />

        //get the password test value


        return (

            <>

            <Helmet>
                <title>Register</title>
                <meta name="description" content="Register" />
            </Helmet>

            <div className="header bg-secondary py-7 py-lg-8 pt-lg-9">
                <Container>
                    <div className="header-body text-center mb-7">
                        <Row className="justify-content-center">
                            <Col className="px-5" lg="6" md="8" xl="5">
                                <h1>Create an account</h1>
                                <p className="text-lead ">Building your brand is just a step away.</p>
                            </Col>
                        </Row>
                    </div>
                </Container>
                <div className="separator separator-bottom separator-skew zindex-100">
                    <svg xmlns="http://www.w3.org/2000/svg" preserveAspectRatio="none" version="1.1" viewBox="0 0 2560 100" x="0" y="0" >
                        <polygon className="fill-default" points="2560 0 2560 100 0 100"/>
                    </svg>
                </div>
            </div>


            <Container className="mt--8 pb-5">
                <Row className="justify-content-center">
                    <Col lg="6" md="8">

                        <Card className="bg-white border-0">

                            <CardHeader className="bg-transparent pb-5">

                                <div className="text-muted text-center mt-2 mb-4">
                                    <small>Sign up with</small>
                                </div>

                                <div className="text-center">

                                    <Button
                                        className="btn-neutral btn-icon"
                                        color="default"
                                        onClick={() => window.location.href = keys.API_URL + '/auth/google?redirect=' + window.location}
                                    >
                                        <span className="btn-inner--icon mr-1">
                                            <img alt="..." src={require("assets/argon/img/icons/common/google.svg")} />
                                        </span>

                                        <span className="btn-inner--text">Google</span>
                                    </Button>
                                </div>

                            </CardHeader>

                            <CardBody className="px-lg-5 py-lg-5">

                                <div className="text-center text-muted mb-4">
                                    <small>Or sign up with credentials</small>
                                </div>
{/* 
                                <Form role="form">

                                    <FormGroup>

                                        {this.state.given_nameState === "invalid" && (
                                            <div className="text-danger font-italic"> <small> Required</small> </div>
                                        )}

                                        <InputGroup className="input-group-merge input-group-alternative mb-3">

                                            <InputGroupAddon addonType="prepend">
                                                <InputGroupText>
                                                    <i className="ni ni-circle-08" />
                                                </InputGroupText>
                                            </InputGroupAddon>

                                            <Input
                                                onKeyDown={this._handleKeyDown}
                                                name="fname"
                                                id="given_name"
                                                value={this.state.given_name || ''}
                                                placeholder="First Name"
                                                type="text"
                                                onChange={e => this.onInputChange(e, "given_name") }
                                                onFocus={() => this.setState({ focusedGivenName: true })}
                                                onBlur={() => this.setState({ focusedGivenName: false })}
                                            />

                                            <div className="valid-feedback">Looks good!</div>

                                        </InputGroup>

                                    </FormGroup>

                                    <FormGroup >

                                        {this.state.family_nameState === "invalid" && (
                                            <div className="text-danger font-italic"> <small> Required</small> </div>
                                        )}
                                        <InputGroup className="input-group-merge input-group-alternative mb-3">

                                            <InputGroupAddon addonType="prepend">
                                                <InputGroupText>
                                                    <i className="ni ni-circle-08" />
                                                </InputGroupText>
                                            </InputGroupAddon>

                                            <Input
                                                onKeyDown={this._handleKeyDown}
                                                id="family_name"
                                                value={this.state.family_name || ''}
                                                name="lname"
                                                placeholder="Last Name"
                                                type="text"
                                                onChange={e => this.onInputChange(e, "family_name") }
                                                onFocus={() => this.setState({ focusedFamilyName: true })}
                                                onBlur={() => this.setState({ focusedFamilyName: false })}
                                            />

                                            <div className="valid-feedback">Looks good!</div>

                                        </InputGroup>

                                    </FormGroup>

                                    <FormGroup>

                                        {this.state.emailState === "invalid" && (
                                            <div className="text-danger font-italic"> <small> Required</small> </div>
                                        )}

                                        <InputGroup className="input-group-merge input-group-alternative mb-3">

                                            <InputGroupAddon addonType="prepend">
                                                <InputGroupText>
                                                    <i className="ni ni-email-83" />
                                                </InputGroupText>
                                            </InputGroupAddon>

                                            <Input
                                                onKeyDown={this._handleKeyDown}
                                                name="email"
                                                placeholder="Email"
                                                type="email"
                                                value={this.state.email || ''}
                                                onChange={e => this.onInputChange(e, "email") }
                                                onFocus={() => this.setState({ focusedEmail: true })}
                                                onBlur={() => this.setState({ focusedEmail: false })}
                                            />

                                        </InputGroup>
                                    </FormGroup>

                                    <FormGroup>

                                        {this.state.passwordState === "invalid" && (
                                            <div className="text-danger font-italic"> <small> Required</small> </div>
                                        )}

                                        {pt && pt.optionalTestErrors && pt.optionalTestErrors.length ? (
                                            <>

                                           { pt.optionalTestErrors.map((p, index) => {
                                                return <div key={index} className="text-danger font-italic"> <small> {p}</small> </div>
                                            })}
                                            </>

                                        ): null}

                                        <InputGroup className="input-group-merge input-group-alternative">

                                            <InputGroupAddon addonType="prepend">
                                                <InputGroupText>
                                                    <i className="ni ni-lock-circle-open" />
                                                </InputGroupText>
                                            </InputGroupAddon>

                                            <Input
                                                onKeyDown={this._handleKeyDown}
                                                name="password"
                                                placeholder="Password"
                                                type="password"
                                                value={this.state.password || ''}
                                                onChange={e => this.onInputChange(e, "password") }
                                                onFocus={() => this.setState({ focusedPassword: true })}
                                                onBlur={() => this.setState({ focusedPassword: false })  }
                                            />

                                        </InputGroup>
                                    </FormGroup>

                                    <FormGroup>

                                        {this.state.password_confirmState === "invalid" && (
                                            <div className="text-danger font-italic"> <small> Required</small> </div>
                                        )}


                                        <InputGroup className="input-group-merge input-group-alternative">

                                            <InputGroupAddon addonType="prepend">
                                                <InputGroupText>
                                                    <i className="ni ni-lock-circle-open" />
                                                </InputGroupText>
                                            </InputGroupAddon>

                                            <Input
                                                onKeyDown={this._handleKeyDown}
                                                name="password_confirm"
                                                placeholder="Confirm Password"
                                                type="password"
                                                value={this.state.password_confirm || ''}
                                                onChange={e => this.onInputChange(e, "password_confirm") }
                                                onFocus={() => this.setState({ focusedPassword: true })}
                                                onBlur={() => this.setState({ focusedPassword: false })}
                                            />

                                        </InputGroup>
                                    </FormGroup>

                                    <div className="text-muted font-italic">
                                        <small>
                                            password strength:{" "}
                                            {pt ? (

                                                pt.strong ?  <span className="text-success font-weight-700"> strong  </span> :
                                                <span className="text-warning font-weight-700"> medium  </span>

                                            ): (
                                                null
                                            ) }
                                        </small>
                                    </div>

                                    <Row className="my-4">
                                        <Col xs="12">

                                            {this.state.termsState === "invalid" && (
                                                <div className="text-danger font-italic"> <small> Required</small> </div>
                                            )}

                                            <div className="custom-control custom-control-alternative custom-checkbox">
                                                <input
                                                    onClick={() => this.setState({terms: !this.state.terms})}
                                                    value={this.state.terms || ''}
                                                    className="custom-control-input"
                                                    id="customCheckRegister"
                                                    type="checkbox"
                                                />
                                                <label className="custom-control-label" htmlFor="customCheckRegister">
                                                    <span className="text-muted">
                                                        I agree with the{" "}
                                                        <a target="_blank" rel="noopener noreferrer" href="https://google.com" > Privacy Policy  </a>
                                                    </span>
                                                </label>
                                            </div>
                                        </Col>
                                    </Row>

                                    <div className="text-center">
                                        {this.state.errorMessage && (
                                            <div className="text-danger font-italic">
                                            <small>{this.state.errorMessage}</small>
                                        </div>
                                        )}
                                        <Button id="createAccountButton" onClick={this.onSignUp} className="mt-4" color="info" type="button"> Create account </Button>
                                    </div>
                                </Form> */}
                            </CardBody>
                        </Card>

                        <Row className="mt-3">

							<Col xs="6">
								<Link className="text-light" to="/login" >
									<small>Account Login</small>
								</Link>
							</Col>

                            <Col className="text-right" xs="6">
								<Link className="text-light" to="/forgot" >
									<small>Forgot Password</small>
								</Link>
							</Col>


						</Row>

                    </Col>
                </Row>
            </Container>
            </>

        );
    }
}

const mapStateToProps = state => {
	return {
	    viewing_user: state.auth.viewing_user,
	};
};

export default connect(mapStateToProps, '')(Register);
